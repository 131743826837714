import React from 'react';
import useAuth from '../hooks/useAuth';
// import Button from '@mui/material/Button';
// import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Modal = () => {
    // Global
    const { modal, showModal, setShowModal } = useAuth();

    // Close Flash Message
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowModal(false);
    };

    let dialogTitleBlock = undefined
    if( modal.title != null ){
        dialogTitleBlock = 
        <DialogTitle id="alert-dialog-title" sx={{padding: 0}}>
            {modal.title}
        </DialogTitle>
    }

    return (
        <>
            <Dialog
                open={showModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={false}
                sx={{
                    '.MuiDialog-paperFullWidth': { margin: '10px', maxHeight: '100%', maxWidth: '100%', width: '100%' },
                    '.MuiDialogContent-root': {padding: '0px'},
                }}
            >
                {dialogTitleBlock}
                <DialogContent>
                    {modal.content}
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Choisir</Button>
                </DialogActions> */}
            </Dialog>
        </>
    );
};

export default Modal;
