import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useCookies } from 'react-cookie';
import useAuth from '../hooks/useAuth';

const MenuFooter = ({item = 'menus', unsoldsRemain = 0}) => {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [displayUnsolds, setDisplayUnsolds] = useState(false);
    const [cookie, setCookie] = useCookies([`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`]);

    const getCanteenInformations = async () => {
        try {     
            const result = await axiosPrivate.get('/guests/canteen', {
                params: {
                    childId: auth.childId,
                },
                withCredentials: true ,
            })

            if( result.data.canteen.unsoldActivated ){
                setDisplayUnsolds(true)
            }
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getCanteenInformations()
    }, []);

    let menuActive = ('menus' === item ) ? 'menu-active' : ''
    let jabuActive = ('jabu' === item ) ? '-active' : ''
    let unsoldActive = ('unsold' === item ) ? 'unsolds-active' : ''
    let unsoldCountActive = ('unsold' === item ) ? 'unsolds-count-active' : ''
    //let newsActive = ('news' === item ) ? 'news-active' : ''
    let reviewActive = ('review' === item ) ? 'review-active' : ''

    let menuLabelActive = ('menus' === item ) ? ' menus-label' : ''
    let jabuLabelActive = ('jabu' === item ) ? ' jabu-label' : ''
    let unsoldLabelActive = ('unsold' === item ) ? ' unsold-label' : ''
    //let newsLabelActive = ('news' === item ) ? ' news-label' : ''
    let reviewLabelActive = ('review' === item ) ? ' review-label' : ''

    let unsoldCountBlock = ''
    if( displayUnsolds ){
        unsoldCountBlock = <span className={`unsolds-count ${unsoldCountActive}`}>{unsoldsRemain}</span>
    }

    const newcomer = 'true' == cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`]
    if( newcomer ){
        setTimeout(() => {
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`, false, { path: '/' });
        }, 3000)
    }

    return (
        <>
            { ( newcomer ) &&
                <div className='welcome-points-container'>
                    <div className='welcome-points'>
                        <div><i style={{fontSize: '40px'}} className='fa-solid fa-gift'></i></div>
                        <div className='welcome-points-text'>Jabu vous offre 10 points de bienvenue !</div>
                    </div>
                </div>
            }

            <div className='meal-footer'>
                <Link to='/' className='d-flex flex-grow-1 flex-shrink-1 w-100'>
                    <div className='flex-column d-flex justify-content-center align-content-center flex-grow-1 flex-shrink-1 m-1 w-100'>
                        <span className='d-flex justify-content-center'>
                            <i className={`fa-regular fa-calendar-days ${menuActive}`}></i>
                        </span>
                        
                        <span className={`menu-label d-flex justify-content-center${menuLabelActive}`}>Menus</span>
                    </div>
                </Link>

                <Link to='/unsolds' className='d-flex flex-grow-1 flex-shrink-1 w-100'>
                    <div className='flex-column d-flex justify-content-center align-content-center flex-grow-1 flex-shrink-1 m-1 w-100'>
                        <span className='d-flex justify-content-center'>
                            <i className={`fa-solid fa-arrows-rotate ${unsoldActive}`}></i>
                            {unsoldCountBlock}
                        </span>
                        
                        <span className={`menu-label d-flex justify-content-center${unsoldLabelActive}`}>Invendus</span>
                    </div>
                </Link>

                {/*<Link to='/news' className='d-flex flex-grow-1 flex-shrink-1 w-100'>
                    <div className='flex-column d-flex justify-content-center align-content-center flex-grow-1 flex-shrink-1 m-1 w-100'>
                        <span className='d-flex justify-content-center'>
                            <i className={`fa-regular fa-newspaper ${newsActive}`}></i>
                        </span>
                            
                        <span className={`menu-label d-flex justify-content-center${newsLabelActive}`}>Actualités</span>
                    </div>
                </Link>*/}

                <Link to='/review' className='d-flex flex-grow-1 flex-shrink-1 w-100'>
                    <div className='flex-column d-flex justify-content-center align-content-center flex-grow-1 flex-shrink-1 m-1 w-100'>
                        <span className='d-flex justify-content-center'>
                            <i className={`fa-solid fa-star ${reviewActive}`}></i>
                        </span>

                        <span className={`menu-label d-flex justify-content-center${reviewLabelActive}`}>Satisfaction</span>
                    </div>
                </Link>

                <Link to='/my-points' className='d-flex flex-grow-1 flex-shrink-1 w-100'>
                    <div className='flex-column d-flex justify-content-center align-content-center m-1 flex-grow-1 flex-shrink-1 w-100'>
                        <span className='d-flex justify-content-center'>
                            <img className='menu-icon' src={`/images/jabu${jabuActive}-icon.png`} alt='Mon JABU'></img>
                        </span>
                        
                        <span className={`menu-label d-flex justify-content-center${jabuLabelActive}`}>Mon Jabu</span>
                    </div>
                </Link>
            </div>
        </>
    )
}

export default MenuFooter
