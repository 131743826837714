import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import useAuth from '../hooks/useAuth';

const Tutorial = ({step}) => {
    const [cookie, setCookie, removeCookie] = useCookies({});
    const { auth, setAuth } = useAuth();

    let nextLink = `/tutorial/${step + 1}`
    let previousButton = '', nextButton = '', title = '', content = '', image = ''
    let step1 = 'step-inactive', step2 = 'step-inactive', step3 = 'step-inactive', step4 = 'step-inactive'

    if( 1 === step ){
        nextButton = <i className='tutorial-nav-button fa-solid fa-circle-chevron-right'></i>
        title = 'Bienvenue sur Jabu !'
        content = 'L\'application qui vous aide à mieux manger et moins jeter !'
        step1 = 'step-active'
        image = 'tutorial-1.png'
    }

    if( 2 === step ){
        previousButton = <i className='tutorial-nav-button fa-solid fa-circle-chevron-left'></i>
        nextButton = <i className='tutorial-nav-button fa-solid fa-circle-chevron-right'></i>
        title = 'Ajoutez Jabu'
        content = ''
        step2 = 'step-active'
        image = 'tutorial-2.png'
    }

    if( 3 === step ){
        previousButton = <i className='tutorial-nav-button fa-solid fa-circle-chevron-left'></i>
        nextButton = <i className='tutorial-nav-button fa-solid fa-circle-chevron-right'></i>
        title = 'Bienvenue sur Jabu !'
        content = 'Faites votre choix jusqu\'à la veille. Aider à anticiper, c\'est déjà lutter contre le gaspillage.'
        step3 = 'step-active'
        image = 'tutorial-2.png'
    }

    if( 4 === step ){
        nextLink = '/'
        previousButton = <i className='tutorial-nav-button fa-solid fa-circle-chevron-left'></i>
        nextButton = <span className='tutorial-go'>Go !</span>
        title = 'Bienvenue sur Jabu !'
        content = 'Votre avis compte ! Partagez-le et participez à nos actions anti-gaspi pour accumuler des points et profiter de récompenses.'
        step4 = 'step-active'
        image = 'tutorial-3.png'
    }

    useEffect(() => {
        let children = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_children`] ?? 'undefined';
        if (children !== 'undefined') {
            setAuth({ ...auth, childId: children.default.id });
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, { ...auth, childId: children.default.id }, { path: '/' })
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_child`, {'id': children.default.id, 'name': children.default.name, 'point': children.default.point}, { path: '/' })
            removeCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_children`, {path: '/'});
        }
    }, []);

    const handleClick = ()=> {
        setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`, false, {path: '/'})
    }

    return (
        <>
            <div className='d-flex justify-content-center'>
                <img className='full-logo' src='/images/logo-black.png' alt='Jabu'></img>
            </div>

            <div className='tutorial-image'>
                <img className='w-100' src={`/images/${image}`} alt='Jabu'></img>
            </div>

            <div className='tutorial-skip'>
                { step >= 2 &&
                    <Link to='/' onClick={handleClick}><span>Ignorer</span></Link>
                }
            </div>

            <div className='tutorial-title'>
                {title}
            </div>

            <div className='tutorial-content flex-column'>
                {content}
                {step === 2 &&
                    <>
                        <div className='d-flex flex-column justify-content-center align-items-center' style={{fontSize: '14px'}}>
                            <p>Sur Safari pour Apple <img src='/images/tutorial-iphone-1.png' alt='iphone1'></img> puis sélectionnez &quot;Sur l&apos;écran d&apos;accueil&quot; <img src='/images/tutorial-iphone-2.png' alt='iphone2'></img></p>
                            <p>Sur Chrome pour Android <img src='/images/tutorial-android-1.png' alt='android'></img>  puis sélectionnez &quot;Ajouter à l&apos;écran d&apos;accueil&quot;</p>
                        </div>
                    </>
                }
            </div>

            <div className='tutorial-navigation'>
                <div className='tutorial-navigation-button'>
                    <Link to={`/tutorial/${step - 1}`}>{previousButton}</Link>
                </div>

                <div className='d-flex flex-grow-1 justify-content-center'>
                    <i className={`fa-solid fa-minus ${step1}`}></i>
                    <i className={`fa-solid fa-minus ${step2}`}></i>
                    <i className={`fa-solid fa-minus ${step3}`}></i>
                    <i className={`fa-solid fa-minus ${step4}`}></i>
                </div>

                <div className='tutorial-navigation-button'>
                    { step === 3 ? (
                        <Link to={nextLink} onClick={handleClick}>{nextButton}</Link>
                    ) : (
                        <Link to={nextLink}>{nextButton}</Link>
                    )}
                </div>
            </div>
        </>
    )
}

export default Tutorial
