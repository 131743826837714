import React, { useState } from 'react';
import useAuth from '../../hooks/useAuth';
import MealDetails from './MealDetails';

const MealCard = ({ userDietaryConstraints=null, dietaryConstraints=null, getValues, setValue, id, name, price, components, tags = [], allergens = [], total, setTotal, register, expired=false }) => {
    const { setModal, setShowModal } = useAuth();
    const [, setChecked] = useState(false);

    let allerg = false

    if( null != dietaryConstraints && null != userDietaryConstraints ){
        dietaryConstraints.forEach( (dietaryConstraint) => {
            if( false == allerg ){
                if( userDietaryConstraints.indexOf(dietaryConstraint.id) >= 0 ){
                    allerg = true
                }
            }
        })
    }
    
    const choose = async (event, id, price) => {
        let dishes = getValues('dishes');
        event.stopPropagation();
        event.preventDefault();


        if (document.querySelector('input[name="dishes"][value="' + id + '"]').checked) {
            document.querySelector('input[name="dishes"][value="' + id + '"]').checked = false
            setTotal(total-price);
            if (dishes.includes(String(id))) {
                const index = dishes.indexOf(String(id));
                dishes.splice(index, 1);
            }
        } else {
            document.querySelector('input[name="dishes"][value="' + id + '"]').checked = true
            setTotal(total+price);

            // One Choice par choix
            const inputElement = event.target;
            let mealListDiv = inputElement.closest('.meal-list');
            let checkboxes = mealListDiv.querySelectorAll('input');
            checkboxes.forEach(checkbox => {
                if (checkbox !== inputElement) {
                    checkbox.checked = false;
                }
                if (dishes.includes(checkbox.value)) {
                    const index = dishes.indexOf(checkbox.value);
                    dishes.splice(index, 1);
                }
            });

            dishes.push(String(id));
            setValue('dishes', dishes);
        }
    }

    const details = async (event, id, name, price, tags) => {
        event.stopPropagation();
        event.preventDefault();
        setModal({content: <MealDetails key={id} id={id} setChecked={setChecked} getValues={getValues} setValue={setValue} name={name} price={price} components={components} tags={tags} allergens={allergens} total={total} setTotal={setTotal}></MealDetails>})
        setShowModal(true)
    }

    let tagsImages = tags.slice(0, 6).map((tag, key) =>
        <img style={{width:20}} key={key} src={`/images/${tag.icon}`} alt={tag.name}></img>
    );

    if( tagsImages.length == 0 ){
        tagsImages = <span>&nbsp;</span>
    }

    let priceBlock = undefined
    if( price > 0 ){
        priceBlock = 
            <span className='price'>{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price) }</span>
    }

    if( allerg ){
        priceBlock = 
            <span className='alert-allergen'>!</span>
    
    }

    if( false == expired ){
        return (
            <>
                <div className='d-inline-block'>
                    <input type="checkbox" name="dishes" value={ id } {...register('dishes')} />
                    <span role="presentation" className='meal' onClick={(event) => choose(event, id, price)}>
                        <span style={{flex:1, display:'flex', fontWeight: 500}}>{ name }</span>
                        {priceBlock}
                        <span>{ tagsImages }</span>
                    </span>
                    
                    <div role="presentation" className='meal-info-f' onClick={(event) => details(event, id, name, price, tags)}>
                        <i className='fa-solid fa-circle-info'></i>
                        {/* <img src='/images/info-icon.png' alt='info'></img> */}
                    </div>
                </div>
            </>
        )
    }else{
        return (
            <>
                <div className='d-inline-block'>
                    <input type="checkbox" name="dishes" value={ id } />
                    <span role="presentation" className='meal'>
                        <span style={{flex:1, display:'flex', fontWeight: 500}}>{ name }</span>
                        {priceBlock}
                        <span>{ tagsImages }</span>
                    </span>
                    
                    <div role="presentation" className='meal-info-f' onClick={(event) => details(event, id, name, price, tags)}>
                        <i className='fa-solid fa-circle-info'></i>
                        {/* <img src='/images/info-icon.png' alt='info'></img> */}
                    </div>
                </div>
            </>
        )
    }
}

export default MealCard
