import React, { useEffect } from 'react';
import ProfileSection from '../../components/Profile/ProfileSection';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const UserProfile = () => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#0B50A4');
   
    // Provider
    const { auth } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        // Redirect if not choice
        if (auth?.canteenId === null && auth?.childId === null) {
            navigate('/register-model');
        }
    }, [])

    return (
        <div className='profil-page d-flex min-vh-100 justify-content-center'>
            <div className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                <div className='profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center'>
                    <div className='d-flex profile-header-back'>
                        <Link to='/'>
                            <span style={{backgroundColor: '#DADADA', borderRadius: '20px', padding: '10px' }}>
                                <i style={{color: '#0B50A4', fontSize: '16px', width: '18px', textAlign: 'center', fontWeight: 'bold' }} className='fa-solid fa-chevron-left'></i>
                            </span>
                        </Link>
                    </div>
                    <div className='d-flex justify-content-center flex-grow-1 profile-header-text'>Mon profil</div>
                </div>

                <div className='d-flex flex-column profile-block'>
                    <div className='d-flex'>
                        <div className='profile-info'>
                            <div className='avatar-name-block' style={{ margin: '0 5px'}}>
                                <span className='avatar-name'>{auth.firstName.slice(0,1)}{auth.lastName.slice(0,1)}</span>
                                {/* <img className='avatar border-dark' alt='Avatar' src='/images/user.png'></img> */}
                            </div>
                            <div>
                                <div className='profile-username'>{auth.firstName}</div>
                                <div className='profile-email'>{auth.username}</div>
                            </div>
                        </div>

                        {/*{ auth.canteenId !== null &&
                            <Link to='/my-points'>
                                <div className='profile-points'>
                                    <div className='d-flex'>
                                        <img className='points-img' src='/images/world-cup-icon.png' alt='Mes points'></img>
                                    </div>

                                    <div className='d-flex profile-points-amount'>{auth.jabuPoint} points</div>
                                </div>
                            </Link>
                        }*/}
                    </div>

                    <div className='d-flex flex-column'>
                        <div className='profile-section-title'>Mon profil</div>
                        <div className='profile-section-content d-flex flex-column'>
                            <ProfileSection icon='profile' name='Mon compte' info='Effectuer des modifications' route='my-infos'></ProfileSection>
                            { auth.canteenId !== null &&
                                <ProfileSection icon='house' name='Ma cantine' info='Changer ou ajouter une cantine' route='my-canteens'></ProfileSection>
                            }
                            { auth.childId !== null &&
                                <ProfileSection icon='house' name='Ma famille' info='Paramétrer et partager votre compte famille' route='my-family'></ProfileSection>
                            }
                            <ProfileSection icon='allergen' name='Mes allergies' info='Modifier mes allergies' route='my-constraint'></ProfileSection>
                            <ProfileSection icon='constraint' name='Mon régime alimentaire' info='Modifier mon régime alimentaire' route='my-dietetary'></ProfileSection>
                            {/*<ProfileSection icon='favorite' name='Mes plats favoris' info='Modifier mes plats favoris' route='my-favorite-meals'></ProfileSection>*/}
                            {/*<ProfileSection icon='profile' name='Invités' info='Ajouter des invités à votre reservation' route='my-invitation'></ProfileSection>*/}
                            {/* <ProfileSection icon='money' name='Mes paiements' info='Moyens de paiements et factures' route='my-payments'></ProfileSection> */}
                            <ProfileSection icon='shield' name='Changer mon mot de passe' info='Définir un nouveau mot de passe' route='my-password'></ProfileSection>
                            {/*<ProfileSection icon='history' name='Historique' info='Consulter l’historique des commandes' route='my-history'></ProfileSection>*/}
                            <ProfileSection icon='history' name='Comment ça fonctionne ?' info='Tutoriel' route='tutorial'></ProfileSection>
                        </div>

                        <div className='profile-section-title'>Autre</div>
                        <div className='profile-section-content d-flex flex-column'>
                            <ProfileSection icon='info2' name='Aide et support' route='help'></ProfileSection>
                            <ProfileSection icon='lock' name='Politique de confidentialité' route='confidential'></ProfileSection>
                            <ProfileSection icon='list' name='CGVU' route='cgvu'></ProfileSection>
                            <ProfileSection icon='logout' name='Déconnexion' route='logout'></ProfileSection>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserProfile;
