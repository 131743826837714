import React from 'react';
import { Link, useParams } from 'react-router-dom';

const MealConfirmation = () => {
    let params = useParams()
    let today = new Date()
    let currentDate = params?.date ? params.date : today.toJSON().slice(0, 10)
    let aft = new Date(currentDate)
    let dayAfter = new Date(aft.setDate(aft.getDate() + 1))

    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#FFFFFF');

    return (
        <div className='home-page d-flex min-vh-100 justify-content-center'>
            <div style={{backgroundColor: 'white'}} className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                <div className='d-flex flex-grow-1 flex-shrink-1 mt-2 min-vw-100'>
                    <div className='confirmation-info'>Bien reçu, bon appétit !</div>
                </div>

                <div className='confirmation-info-borne flex-grow-1 flex-shrink-1 mt-2 min-vw-100 justify-content-center align-items-center' style={{maxHeight: '30px', marginBottom: '20px', backgroundColor: '#E984B7'}}>
                    <div>Sur borne, pensez à vous déconnecter :)</div>
                </div>

                <div className='gain-points rounded-circle'>
                    <div className='points-claimed-info'>Vous venez de gagner</div>
                    <div className='points-claimed'>10pts</div>
                </div>

                <div className='points-info'>
                    {/*<i>Vos points Jabu seront automatiquement crédités lorsque vous flasherez le QR code présent dans votre cantine</i>*/}
                </div>


                <div className='d-flex justify-content-center align-items-center mb-5 flex-column'>
                    <Link style={{marginBottom: '20px'}} to={`/meal/${dayAfter.toISOString().slice(0, 10)}`}>
                        <button className='next-day'>Passer au jour suivant</button>
                    </Link>

                    <Link to={'/my-points'}>
                        <button className='see-points'>Consulter mon solde Jabu</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default MealConfirmation;
