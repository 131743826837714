import React from 'react';

const LoginTemplate = ({ child }) => {
    return (
        <>
            <div className="login-template min-vh-100">
                <div className="d-flex col-12 justify-content-center align-items-center flex-column">
                    <span>
                        <img className="full-logo" alt="" src="/images/logo.png"></img>
                    </span>

                    <span className="login-subtext">
                        Manger mieux, jeter moins
                    </span>

                    { child }
                </div>
            </div>

            {/*<div className="desktop-login-template">
                <img alt="Sample" src="/images/login.png"></img>
            </div>*/}
        </>
    );
};

export default LoginTemplate;
