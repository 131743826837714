import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useAuth from '../../hooks/useAuth';
import MealCalendar from '../../components/Meal/MealCalendar';
import MealMenu from '../../components/Meal/MealMenu';
//import MealAttendance from '../../components/Meal/MealAttendance';
import ProfileHeader from '../../components/Profile/ProfileHeader';
import MenuFooter from '../../components/MenuFooter';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { CookiesProvider, useCookies } from 'react-cookie';
import dayjs from 'dayjs';

const HomePage = () => {
    const navigate = useNavigate();
    const param = useParams()
    const { auth, setAuth, setFlash, setShowFlash, setIsLoading } = useAuth();
    const [scenario, setScenario] = useState('no-answer');
    const [calendarDisplay, setCalendarDisplay] = useState(false);
    const [mealsDisplay, setMealsDisplay] = useState(false);
    const [meals, setMeals] = useState(null);
    const [, setSlots] = useState([]);
    const [userAllergens, setUserAllergens] = useState();
    const [userDietaryConstraints, setUserDietaryConstraints] = useState();
    const [menuId, setMenuId] = useState('0');
    const [reserved, setReserved] = useState(false);
    const [total, setTotal] = useState(0);
    const axiosPrivate = useAxiosPrivate();
    const [reservations, setReservations] = useState([])
    const [todayReservation, setTodayReservation] = useState(null)
    const [todayComing, setTodayComing] = useState(false)
    const [reservationInfos, setReservationInfos] = useState([])
    const [reservationPlatter, setReservationPlatter] = useState([])
    const [reservationMeal, setReservationMeal] = useState([])
    const [reservationAccomp, setReservationAccomp] = useState([])
    const [reservationCheese, setReservationCheese] = useState([])
    const [reservationDesssert, setReservationDessert] = useState([])
    const [displayScenario, setDisplayScenario] = useState(false);
    const [expirationDays, setExpirationDays] = useState(0);
    const [dayLimit, setDayLimit] = useState(0);
    const [limitBreakDay, setLimitBreakDay] = useState(0);
    const { reset, register, handleSubmit, getValues, setValue } = useForm();
    const completeDays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
    const completeMonths = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
    const [cookie, setCookie] = useCookies({});
    const canteenId = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]['id'] ?? null
    const canteenName = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]['name'] ?? 'Choisir une cantine'
    const lastPart = window.location.pathname.split('/').pop()
    const [unsolds, setUnsolds] = useState(true);

    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#EE4447');

    const coming = async (come, dayAfter) => {
        setIsLoading(true);

        if( scenario == 'no-answer' ){
            await axiosPrivate.post('/guests/reservation/meal', {date: currentDate, reserved: come, canteenId: canteenId}, { withCredentials: true })
            if (null !== auth.childId) {
                let child = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_child`];
                setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_child`, {id: child.id, name: child.name, point: child.point + 10 }, { path: '/' })
            } else {
                setAuth({ ... auth, jabuPoint: auth.jabuPoint + 10});
                setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, { ... auth, jabuPoint: auth.jabuPoint + 10 }, { path: '/' });
            }
        }else{
            await axiosPrivate.put('/guests/reservation/meal', {date: currentDate, reserved: come, canteenId: canteenId}, { withCredentials: true })
        }

        if( !come ){
            setScenario('no-answer')
            window.location = `/meal/${dayAfter}`;
        }else{
            setScenario('he-comes')
            window.location = `/meal/${currentDate}`;
        }


        setIsLoading(false);
    }

    let params = useParams()
    let today = new Date()
    let currentDate = params?.date ? params.date : today.toJSON().slice(0, 10)
    let slotType = params?.type ? params.type : 1
    let currentDay = new Date(currentDate)
    let aft = new Date(currentDate)
    let dayAfter = new Date(aft.setDate(aft.getDate() + 1))
    //const slots = ['10:15', '12:00', '12:30', '13:00', '13:30']

    const getReservations = async (dayLimitt) => {
        setIsLoading(true);
        try {     
            const result = await axiosPrivate.get(`/guests/reservation/meal?currentDate=${currentDate}&date=${dayjs(dayLimitt).format('YY-MM-DD')}&canteenId=${canteenId}`, { withCredentials: true })
            const reservationsData = result.data['dates']
            setReservations(reservationsData)
            setTodayReservation(reservationsData[currentDate]['reserved'])
            setTodayComing(reservationsData[currentDate]['here'])

            if( null == reservationsData[currentDate]['reserved'] ){
                setScenario('no-answer')
            }

            if( true == reservationsData[currentDate]['reserved'] ){
                setScenario('he-comes')
            }

            if( false == reservationsData[currentDate]['reserved'] ){
                setScenario('he-dont-come')
            }

            if( currentDay < dayLimitt ){
                setScenario('vote-expiration')
            }

            setCalendarDisplay(true)
            setIsLoading(false);
        } catch (error) {
            console.log(error)
            if('Canteen not exist' == error.response.data['hydra:description']){
                setScenario('no-canteen')
            }
        }
    };

    const getCanteenInformations = async () => {
        try {     
            const result = await axiosPrivate.get('/guests/canteen', {
                params: {
                    childId: auth.childId,
                },
                withCredentials: true,
            })

            setExpirationDays(result.data.canteen.daysLimitBreak)
            let limit = new Date()
            limit.setHours(0, 0, 0, 0)
            let breakDay = new Date()
            breakDay.setHours(0, 0, 0, 0)
            let dayLimitt = new Date(limit.setDate(limit.getDate() + result.data.canteen.daysLimitBreak))
            setDayLimit(dayLimitt)
            setLimitBreakDay(new Date(breakDay.setDate(breakDay.getDate() + result.data.canteen.daysLimitBreak + 1)))

            if (params?.date == null) {
                var year = dayLimitt.getFullYear();
                var month = ('0' + (dayLimitt.getMonth() + 1)).slice(-2);
                var day = ('0' + dayLimitt.getDate()).slice(-2);
                var formattedDate = year + '-' + month + '-' + day;

                window.location = `/meal/${formattedDate}`;
            }

            return dayLimitt
        } catch (error) {
            console.log(error)
        }
    };

    const getMeals = async () => {
        try {     
            const result = await axiosPrivate.get(`/guests/canteen/menu?date=${currentDate}&canteenId=${canteenId}&slotType=${slotType}`, { withCredentials: true })
            setMeals(result.data)
            setMenuId(result.data.infos.menu_id)

            let timeSlots = []
            result.data.stats.forEach((stat) => {
                timeSlots.push(stat.slot)
            })

            setSlots(timeSlots)

            const responseUser = await axiosPrivate.get('/guests/setting', {
                params: {
                    childId: auth.childId,
                },
                withCredentials: true,
            });
            setUserAllergens(responseUser.data.allergens)
            setUserDietaryConstraints(responseUser.data.dietaryConstraints)
        } catch (error) {
            console.log(error)
            if(404 == error.response.status && 'Canteen not exist' == error.response.data['hydra:description']){
                setScenario('no-canteen')
            }else{
                if( 404 == error.response.status ){
                    setScenario('no-menu')
                }
            }        
        }
        setIsLoading(false);
        setDisplayScenario(true)
        setMealsDisplay(true);
    };

    useEffect(() => {
        if (auth?.canteenId === null && auth?.childId === null) {
            navigate('/register-model');
        }
    }, []);

    useEffect(() => {
        setDisplayScenario(false)
        setIsLoading(true)

        if( null != canteenId ){
            getUnsolds()
            getCanteenInformations().then((dayLimitt) => getReservations(dayLimitt).then(() => getMeals().then()));
        }else{
            setScenario('no-canteen')
            setIsLoading(false);
            setDisplayScenario(true)
        }
    }, [param.date, param.type]);

    useEffect(() => {
        setReserved(false);
        if (scenario === 'he-comes' || scenario === 'vote-expiration') {
            getUserReservations();
        }
    }, [menuId, param.date, param.type, scenario]);

    const getUserReservations = async () => {
        setIsLoading(true);
        try {
            const result = await axiosPrivate.get(`/guests/reservation/menu/meal?menu_id=${menuId}&canteenId=${canteenId}`, { withCredentials: true })
            setReserved(true);
            setReservationInfos(result.data)
            setReservationPlatter(result.data.mealsInformations.find((meal) => meal.type == 1)?.name)
            setReservationMeal(result.data.mealsInformations.find((meal) => meal.type == 2)?.name)
            setReservationAccomp(result.data.mealsInformations.find((meal) => meal.type == 4)?.name)
            setReservationCheese(result.data.mealsInformations.find((meal) => meal.type == 5)?.name)
            setReservationDessert(result.data.mealsInformations.find((meal) => meal.type == 3)?.name)
            setTotal(result.data?.total);
            reset({
                dishes: result.data?.meals.map((meal) => String(meal)) ?? [],
                attendance: result.data?.time.slice(0,5),
            });

            if( true == reservations[currentDate]['reserved'] && scenario !== 'vote-expiration' && lastPart != 'modify' ){
                navigate(`/meal/${currentDate}/1/recap`);
            }
        } catch (error) {
            setReservationInfos([])
            reset({
                dishes: [],
                attendance: null,
            });
        }
        setIsLoading(false);
    };

    // Call Submit Canteen Setting Form
    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Check Data
        if (data.dishes.length === 0) {
            setFlash({ message: 'Veuillez sélectionner au moins un plat'});
            setShowFlash(true);
            setIsLoading(false);

            return;
        }
        /*if (data.attendance === null) {
            setFlash({ message: 'Veuillez sélectionner un créneau'});
            setShowFlash(true);
            setIsLoading(false);

            return;
        }*/

        //Call API
        try {
            if (reserved) {
                await axiosPrivate.put('/guests/reservation/menu/meal', {
                    menu_id: menuId,
                    //reserved_hour: data.attendance + ':00',
                    reserved_hour: '12:00:00',
                    dishes: data.dishes.map((dish) => parseInt(dish)),
                    canteenId:canteenId
                }, {withCredentials: true});
            } else {
                await axiosPrivate.post('/guests/reservation/menu/meal', {
                    menu_id: menuId,
                    //reserved_hour: data.attendance + ':00',
                    reserved_hour: '12:00:00',
                    dishes: data.dishes.map((dish) => parseInt(dish)),
                    canteenId: canteenId
                }, {withCredentials: true});
                setReserved(true);
            }

            setFlash({ message: 'Vote effectué', type: 'success' });
            navigate(`/meal/${dayjs(currentDate).add(1, 'day').format('YYYY-MM-DD')}`);
        } catch (err) {
            console.log(err);
        }

        // Flash Message
        setShowFlash(true);

        // Loader
        setIsLoading(false);
    }

    const getUnsolds = async () => {
        try {    
            const result = await axiosPrivate.get(`/guests/canteen/unsolds?canteenId=${canteenId}&requestedDate=${currentDate}`, { withCredentials: true })
            setUnsolds(result.data)
        } catch (error) {
            setUnsolds({remains:null})
        }
    }

    switch(scenario){
    case 'vote-expiration':
        return ( displayScenario &&
            <div className='home-page d-flex min-vh-100 justify-content-center'>
                <div className='meal-header'>
                    <CookiesProvider>
                        <ProfileHeader firstname={cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['firstName']} lastName={cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['lastName']} points={cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['jabuPoint']} todayReservation={todayReservation} todayComing={todayComing} bg='calendar_reservation' canteenName={canteenName} page='home'></ProfileHeader>
                    </CookiesProvider>
                    <MealCalendar dayLimit={dayLimit} calendarDisplay={calendarDisplay} reservations={reservations} currentDate={currentDate}></MealCalendar>
                </div>

                <div role='presentation' id='meal-infos' style={{alignItems:'initial'}}>
                    <div className='coming-choice d-flex flex-row justify-content-center flex-grow-1 flex-shrink-1'>
                        <div className='expired-date'>{ completeDays[currentDay.getDay()] } {String(currentDay.getDate()).padStart(2, '0')} { completeMonths[currentDay.getMonth()] }</div>
                        
                        {/* <div className='d-flex justify-content-center flex-grow-1'>
                            <button id='chosen-noon-ok' className={(1 == slotType) ? 'chosen-noon-ok slot-type-active' : 'chosen-noon-ok'}>
                                <Link to={`/meal/${currentDate}`}>
                                    <span>Midi</span>
                                </Link>
                            </button>

                            <button id='chosen-evening-ok' className={(3 == slotType) ? 'chosen-evening-ok slot-type-active' : 'chosen-evening-ok'}>
                                <Link to={`/meal/${currentDate}/3`}>
                                    <span>Soir</span>
                                </Link>
                            </button>
                        </div> */}
                    </div>

                    <div className='expired-confirm-resa'>
                        { (reservations[currentDate]['reserved'] == true && reservationInfos.length != 0) && <div className='expired-confirm-ok'>
                            <div className='expired-confirm-ok-text'>Votre vote est confirmé !</div>
                            <div className='expired-confirm-ok-details'>
                                <div className='expired-confirmation-info'>
                                    <span>
                                        <i className='fa-solid fa-bell-concierge'></i>
                                    </span>

                                    <span className='expired-chosen-meal'>
                                        Repas choisi
                                    </span>
                                </div>

                                {reserved && reservationPlatter && 
                                    <div className='expired-dish-infos'>
                                        <div className='expired-dish-category'>Entrée</div>
                                        <div>
                                            {reserved && reservationPlatter}
                                        </div>
                                    </div>
                                }

                                {reserved && reservationMeal && 
                                    <div className='expired-dish-infos'>
                                        <div className='expired-dish-category'>Plat</div>
                                        <div>
                                            {reserved && reservationMeal}
                                        </div>
                                    </div>
                                }

                                {reserved && reservationAccomp && 
                                    <div className='expired-dish-infos'>
                                        <div className='expired-dish-category'>Accomp.</div>
                                        <div>
                                            {reserved && reservationAccomp}
                                        </div>
                                    </div>
                                }

                                {reserved && reservationCheese && 
                                    <div className='expired-dish-infos'>
                                        <div className='expired-dish-category'>Laitages</div>
                                        <div>
                                            {reserved && reservationCheese}
                                        </div>
                                    </div>
                                }

                                {reserved && reservationDesssert && 
                                    <div className='expired-dish-infos'>
                                        <div className='expired-dish-category'>Dessert</div>
                                        <div>
                                            {reserved && reservationDesssert}
                                        </div>
                                    </div>
                                }

                                {/*<div className='expired-confirmation-info'>
                                    <span>
                                        <i className='fa-regular fa-clock'></i>
                                    </span>

                                    <span className='expired-chosen-meal'>
                                        Créneau réservé : 
                                    </span>

                                    <span className='expired-chosen-value'>
                                        {reserved && reservationInfos.time.slice(0, -3)}
                                    </span>
                                </div>*/}
                            </div>
                        </div> }

                        { (reservations[currentDate]['reserved'] == true && reservationInfos.length == 0) && <div className='expired-confirm-no-resa'><div className='expired-confirm-ok-resa'></div><div className='expired-confirm-no-resa-text'>Vous avez indiqué votre presence sans réserver votre repas</div></div> }

                        { reservations[currentDate]['reserved'] == false && <div className='expired-confirm-no-resa'><div className='expired-confirm-missing'></div><div className='expired-confirm-no-resa-text'>Vous avez signalé votre absence pour ce repas</div></div> }

                        { reservations[currentDate]['reserved'] == null && <div className='expired-confirm-no-resa'><div className='expired-confirm-miss-resa'></div><div className='expired-confirm-no-resa-text'>Aucun vote enregistré</div></div> }
                        
                        <div className='expired-info'>
                            <div className='expired-info-icon'><i className='fa-regular fa-calendar-check'></i></div>
                            <div className='expired-info-text'>Les votes sont maintenant clôturés</div>
                        </div>
                    </div>

                    {/*<MealAttendance slotType={slotType} slots={slots} expired={true} stats={meals.stats} register={register} total={total}></MealAttendance>*/}

                    <MealMenu expired={true} userAllergens={userAllergens} userDietaryConstraints={userDietaryConstraints} getValues={getValues} setValue={setValue} register={register} total={total} setTotal={setTotal} mealsDisplay={mealsDisplay} meals={meals?.meals} date={currentDate}></MealMenu>

                    <div className='expired-info' style={{margin: '30px 15px'}}>
                        <div className='expired-info-icon'><i className='fa-solid fa-circle-info'></i></div>
                        <div className='expired-info-subtext'>Les votes ferment la veille de la date de repas. Anticipez dès maintenant vos prochains votes !</div>
                    </div>
                </div>

                <MenuFooter unsoldsRemain={unsolds.remains}></MenuFooter>
            </div>
        );

    case 'no-answer':
        return ( displayScenario &&
            <div className='home-page d-flex min-vh-100 justify-content-center'>
                <div className='meal-header'>
                    <CookiesProvider>
                        <ProfileHeader firstname={cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['firstName']} lastName={cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['lastName']} points={cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['jabuPoint']} todayReservation={todayReservation} todayComing={todayComing} bg='calendar_reservation' canteenName={canteenName} page='home'></ProfileHeader>
                    </CookiesProvider>
                    <MealCalendar dayLimit={dayLimit} calendarDisplay={calendarDisplay} reservations={reservations} currentDate={currentDate}></MealCalendar>
                </div>

                <div role='presentation' id='meal-infos'>
                    <div className='meal-complete-day'>{ completeDays[currentDay.getDay()] } {String(currentDay.getDate()).padStart(2, '0')} { completeMonths[currentDay.getMonth()] }</div>
                    <div className='d-flex flex-column m-2 justify-content-center align-items-center flex-grow-1 flex-shrink-1'>
                        <div className='d-flex justify-content-center not-coming-label'>Indiquez-votre présence pour aider le chef dans sa préparation !</div>
                        <button id='coming' onClick={ () => coming(true, dayAfter.toJSON().slice(0, 10)) }><span>Présent</span><i className="fa-solid fa-check"></i></button>
                        <button id='not-coming' onClick={ () => coming(false, dayAfter.toJSON().slice(0, 10)) }><span>Absent</span><i className="fa-solid fa-xmark"></i></button>
                    </div>

                    { ( currentDay.toJSON().slice(0, 10) == limitBreakDay.toJSON().slice(0, 10) ) && expirationDays > 0 &&
                        <div className='last-day-info'>
                            <div className='expired-info-icon'><i className='fa-regular fa-clock'></i></div>
                            <div className='expired-info-subtext'>Dernier jour pour voter pour ce repas !</div>
                        </div>
                    }
                </div>

                <MenuFooter unsoldsRemain={unsolds.remains}></MenuFooter>
            </div>
        );
    
    case 'he-dont-come':
        return ( displayScenario &&
            <div className='home-page d-flex min-vh-100 justify-content-center'>
                <div className='meal-header'>
                    <CookiesProvider>
                        <ProfileHeader firstname={cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['firstName']} lastName={cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['lastName']} points={cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['jabuPoint']} todayReservation={todayReservation} todayComing={todayComing} bg='calendar_reservation' canteenName={canteenName} page='home'></ProfileHeader>
                    </CookiesProvider>
                    <MealCalendar dayLimit={dayLimit} reservations={reservations} currentDate={currentDate}></MealCalendar>
                </div>

                <div role='presentation' id='meal-infos'>
                    <div className='meal-complete-day'>{ completeDays[currentDay.getDay()] } {String(currentDay.getDate()).padStart(2, '0')} { completeMonths[currentDay.getMonth()] }</div>
                    <div className='d-flex flex-column m-2 justify-content-center align-items-center flex-grow-1 flex-shrink-1'>
                        <div className='d-flex justify-content-center not-coming-label'>Merci pour votre retour ! N&apos;oubliez pas, vous pouvez toujours ajuster votre vote si le cœur vous en dit.</div>
                        { ( currentDay >= dayLimit ) && <button id='coming' onClick={ () => coming(true, dayAfter.toJSON().slice(0, 10)) }><span>Présent</span><i className="fa-solid fa-check"></i></button> }
                        { ( currentDay < dayLimit ) && <button id='coming' className='vote-disabled'><span>Présent</span><i className="fa-solid fa-check"></i></button> }
                        <button id='not-coming-selected' onClick={ () => coming(false, dayAfter.toJSON().slice(0, 10)) }><span>Absent</span><i className="fa-solid fa-xmark"></i></button>
                    </div>
                </div>

                <MenuFooter unsoldsRemain={unsolds.remains}></MenuFooter>
            </div>
        );

    case 'no-canteen':
        return ( displayScenario &&
            <div className='home-page d-flex min-vh-100 justify-content-center'>
                <div className='meal-header'>
                    <CookiesProvider>
                        <ProfileHeader firstname={cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['firstName']} lastName={cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['lastName']} points={cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['jabuPoint']} todayReservation={todayReservation} todayComing={todayComing} bg='calendar_reservation' canteenName={canteenName} page='home'></ProfileHeader>
                    </CookiesProvider>
                    <MealCalendar dayLimit={dayLimit} reservations={reservations} currentDate={currentDate}></MealCalendar>
                </div>

                <div role='presentation' id='meal-infos'>
                    <div className='meal-complete-day'>{ completeDays[currentDay.getDay()] } {String(currentDay.getDate()).padStart(2, '0')} { completeMonths[currentDay.getMonth()] }</div>

                    <div className='no-canteen'>
                        <p>Hé là ! Pas de menus ?<br></br><br></br></p>
                        <p>
                            On dirait bien que vous n&apos;avez pas encore ajouté votre cantine. <br></br>
                            Scannez le QR code ou entrez le code unique de votre cantine et c&apos;est parti pour le festin ! Si votre cantine n&apos;est pas encore dans notre réseau, donnez-nous son nom. <br></br>
                            Nous allons cuisiner ça !
                        </p>
                    </div>

                    <div className='d-flex justify-content-center'>
                        <Link to='/my-canteens'><button style={{marginBottom: '10px'}} type="submit" className='meal-submit border-0'>Ajouter une cantine</button></Link>
                    </div>

                    <div className='d-flex justify-content-center'>
                        <Link target='_blank' to='https://3n8auddrcgs.typeform.com/to/hCU6niny'><button type="submit" className='join-canteen'>Inviter ma cantine à rejoindre Jabu</button></Link>
                    </div>
                </div>

                <MenuFooter unsoldsRemain={unsolds.remains}></MenuFooter>
            </div>
        );

    case 'no-menu':
        return ( displayScenario &&
            <div className='home-page d-flex min-vh-100 justify-content-center'>
                <div className='meal-header'>
                    <CookiesProvider>
                        <ProfileHeader firstname={cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['firstName']} lastName={cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['lastName']} points={cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['jabuPoint']} todayReservation={todayReservation} todayComing={todayComing} bg='calendar_reservation' canteenName={canteenName} page='home'></ProfileHeader>
                    </CookiesProvider>
                    <MealCalendar dayLimit={dayLimit} reservations={reservations} currentDate={currentDate}></MealCalendar>
                </div>

                <div role='presentation' id='meal-infos'>
                    <div className='d-flex p-2'>
                        {/*Oups! On dirait que le chef fait durer le suspense... Pas de menu pour le moment, mais restez connecté, les délices arrivent!*/}
                        Etablissement fermé pour le moment. Revenez plus tard.
                    </div>
                </div>

                <MenuFooter unsoldsRemain={unsolds.remains}></MenuFooter>
            </div>
        );

    case 'he-comes':
        return ( displayScenario &&
            <div className='home-page d-flex min-vh-100 justify-content-center'>
                <div className='meal-header'>
                    <CookiesProvider>
                        <ProfileHeader firstname={cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['firstName']} lastName={cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['lastName']} points={cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['jabuPoint']} todayReservation={todayReservation} todayComing={todayComing} bg='calendar_reservation' canteenName={canteenName} page='home'></ProfileHeader>
                    </CookiesProvider>
                    <MealCalendar dayLimit={dayLimit} calendarDisplay={calendarDisplay} reservations={reservations} currentDate={currentDate}></MealCalendar>
                </div>

                <div role='presentation' id='meal-infos' style={{alignItems:'initial'}}>
                    <div className='coming-choice d-flex flex-row justify-content-center flex-grow-1 flex-shrink-1'>
                        <div className='d-flex justify-content-around flex-grow-1'>
                            <button id='chosen-coming-ok' onClick={ () => coming(true, dayAfter.toJSON().slice(0, 10)) }><span>Présent</span><i className="fa-solid fa-check"></i></button>
                            <button id='chosen-not-coming-ok' onClick={ () => coming(false, dayAfter.toJSON().slice(0, 10)) }><span>Absent</span><i className="fa-solid fa-xmark"></i></button>
                        </div>

                        {/* <div className='d-flex justify-content-center flex-grow-1'>
                            <button id='chosen-noon-ok' className={(1 == slotType) ? 'chosen-noon-ok slot-type-active' : 'chosen-noon-ok'}>
                                <Link to={`/meal/${currentDate}`}>
                                    <span>Midi</span>
                                </Link>
                            </button>

                            <button id='chosen-evening-ok' className={(3 == slotType) ? 'chosen-evening-ok slot-type-active' : 'chosen-evening-ok'}>
                                <Link to={`/meal/${currentDate}/3`}>
                                    <span>Soir</span>
                                </Link>
                            </button>
                        </div> */}
                    </div>

                    <form onSubmit={ handleSubmit(submit) } >
                        <MealMenu userAllergens={userAllergens} userDietaryConstraints={userDietaryConstraints} getValues={getValues} setValue={setValue} register={register} total={total} setTotal={setTotal} mealsDisplay={mealsDisplay} meals={meals?.meals} date={currentDate}></MealMenu>

                        {/*<MealAttendance slotType={slotType} slots={slots} stats={meals.stats} register={register} total={total}></MealAttendance>*/}

                        <div className='confirm-meal'>
                            { ( currentDay >= dayLimit ) && <button type="submit" className='meal-submit border-0'>Valider</button> }
                        </div>
                    </form>
                </div>

                <MenuFooter unsoldsRemain={unsolds.remains}></MenuFooter>
            </div>
        );
    }
};

export default HomePage;
